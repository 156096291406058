// const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

if ("serviceWorker" in navigator) {
  window.addEventListener("load", function () {
    var url = "/sw.js";  // Register as close to root as possible (determines scope)
    navigator.serviceWorker.register(url, {type: "module"}).then(
      function (registration) {
        // Registration was successful
        console.log(
          "ServiceWorker registration successful with scope: ",
          registration.scope
        );
      },
      function (err) {
        // registration failed :(
        console.log("ServiceWorker registration failed: ", err);
      }
    );
  });

  // window.addEventListener("turbo:load", function () {
  document.addEventListener("DOMContentLoaded", function () {
    const meta = document.querySelector("meta[name='stream-badge-count']");
    if (!meta) return;
    const badgeCount = meta.getAttribute("content");
    console.log("Setting badge to", badgeCount);
    navigator.setAppBadge(badgeCount).catch((error) => {
      //Do something with the error.
    });
  });
}

// let deferredPWAPrompt;
// const pwaBtn = document.querySelector(".pwa-install");

// window.addEventListener("beforeinstallprompt", (e) => {
//   // Prevent Chrome 67 and earlier from automatically showing the prompt
//   e.preventDefault();
//   // Stash the event so it can be triggered later.
//   deferredPWAPrompt = e;
//   if (pwaBtn) pwaBtn.style.display = "inline";
// });

// if (pwaBtn) {
//   pwaBtn.addEventListener("click", (e) => {
//     deferredPWAPrompt.prompt();
//   });
// }
