import $ from "jquery";
window.jQuery = $;

require("@fancyapps/fancybox");

import "@fancyapps/fancybox/dist/jquery.fancybox.min.css";

import "./pwa.js";
import "./entries/js/form.js";

// menu...
$(document).on("click", "[data-toggle]", function (e) {
  const $target = $($(this).data("toggle"));
  $target.toggle();
});

$(document).on("click", "[data-lightbox]", function (e) {
  e.preventDefault();
  $.fancybox.open({
    src: $(this).attr("href"),
    type: "image",
  });
});

window.scrollToBottom = function () {
  var s = document.querySelector(".scroll-to-bottom");
  if (s) {
    s.scrollTo(0, s.scrollHeight);
    setTimeout(function () {
      s.scrollTo(0, s.scrollHeight);
    }, 500);
  }
};

// jQuery(document).on("turbo:load", function () {
$(function() {


  // Make all existing entry links open in a new tab
  $(".entry-content a").attr("target", "_blank");

  window.scrollToBottom();
});

// when you're inside a data-today-item input and backspace through an empty input,
// send an htmx delete on the same item
// $(document).on("keydown", "[data-today-item]", function (e) {
//   console.log(e)
//   if (e.which === 8 && $(this).val() === "") {
//     console.log("shit")
//     e.preventDefault();
//     $(this).closest("[data-today-item]").htmx("delete");
//   }
// });
