import $ from "jquery";
import autosize from "autosize";
import {subscribe} from '@github/paste-markdown'

import "./thread.js";

// Submit the form on shift+enter
$(document).on("keydown", "form textarea", function (event) {
  if (event.key == "Enter" && event.metaKey) {
    event.preventDefault();
    $(event.target).parents("form").find("button[type='submit']").trigger("click");
  }
});

function uploadFile(file, onError) {
  console.log(file);

  $.ajax({
    url: "/attachments/create/",
    type: "POST",
    data: {
      name: file.name,
      size: file.size,
      content_type: file.type,
    },
    headers: {
      "X-CSRFToken": $("meta[name='csrftoken']").attr("content"),
    },
    success: function (data) {
      if (!data) {
        alert("Error creating attachment!");
        return;
      }

      $.ajax({
        // xhr: function () {
        //   var xhr = new window.XMLHttpRequest();
        //   xhr.upload.addEventListener(
        //     "progress",
        //     function (event) {
        //       var progress = (event.loaded / event.total) * 100;
        //       attachment.setUploadProgress(progress);
        //     },
        //     false
        //   );
        //   return xhr;
        // },
        url: data.upload_url,
        type: "PUT",
        data: file,
        processData: false,
        contentType: false,

        success: function (event) {
          addAttachmentUUID(data.uuid);
          let inner = file.name;
          if (data.is_image) {
            inner = `<img src="${data.download_url}" />`;
          }
          const el = `<a data-uuid="${data.uuid}" href="${data.download_url}" target="_blank">${inner}</a>`;
          $("form [data-attachments]").append(el);
        },
        error: onError,
      });
    },
    error: onError,
  });
}

function addAttachmentUUID(uuid) {
  const $input = $("form #id_attachment_uuids");
  let val = $input.val();
  if (val && val.length > 0) {
    val = val + "," + uuid;
  } else {
    val = uuid;
  }
  $input.val(val);
}

// removeAttachmentUUID(uuid)
// remove from attachemtn ids
// delete attachment element
// and delete attachment from server

// $(document).on("submit", "#permanent-entry-form", function (e) {
//   e.preventDefault();
//   submitForm(e.target);
//   return false;
// });

// function submitForm(form) {
//   console.log("Submitting");
//   const $form = $(form);
//   $.post($form.attr("action") || ".", $form.serialize())
//     .done(function (e) {
//       $form.find("input[type='hidden'], input[type='text'], textarea").val("");
//       $form.find("[data-thread-indicator]").hide();
//       $form.find("[data-attachments]").html("");
//       autosize.update($form.find("textarea"));

//       // if we're on the homepage, then visit it and reload data?
//       // if not then simple alert to show it was saved?
//       // Turbo.visit("/");
//     })
//     .fail(function (e) {
//       console.log(e);
//       window.alert(e);
//     });
// }

$(document).on("change", ".entry-content :checkbox", function (e) {
  const $this = $(this);
  const checkboxes = $this.parents(".entry-content").find(":checkbox");
  const index = checkboxes.index($this);
  const url = $this.parents("[data-entry]").data("complete-url");

  $.ajax({
    url: url,
    type: "POST",
    data: { index: index, checked: this.checked },
    headers: {
      "X-CSRFToken": $("meta[name='csrftoken']").attr("content"),
    },
    error: function (e) {
      console.log(e);
      $this[0].checked = !$this[0].checked;
    }
  });

});

$(document).on("dragover dragenter", function (e) {
  e.preventDefault();
  e.stopPropagation();
});
$(document).on("drop", function (e) {
  e.preventDefault();
  e.stopPropagation();
  $.each(e.originalEvent.dataTransfer.files, function (k, f) {
    uploadFile(f);
  });
});

// $(document).on("turbo:load", function () {
$(function() {
  // Make todos checkable
  $(".entry-content :checkbox").removeAttr("disabled");

  // on htmx entryAdded event, append the content to #entries if it exists
  document.body.addEventListener("entryAdded", function (evt) {
    if ($("#entries").length > 0) {
      const el = document.createElement("div");
      el.setAttribute("hx-get", evt.detail.value);
      el.setAttribute("hx-trigger", "load");
      $("#entries").append(el);
      htmx.process(el);
    }
  });
});

$(document).on("htmx:afterSwap", function (e) {
  var $form = e.target.nodeName === "FORM" ? $(e.target) : $(e.target).find("form");
  if ($form.length > 0) {
    var $textarea = $form.find("textarea");
    if ($textarea.length > 0) {
      autosize($textarea.attr("rows", 1));
      subscribe($textarea[0]);  // Add paste-markdown
      $textarea.trigger("focus");
    }
  }

  var $entry = $(e.target).find("[data-entry]");
  if ($entry.length > 0) {
    window.scrollToBottom();

    // Make links open in a new tab
    $(".entry-content a").attr("target", "_blank");
  }

});
