import $ from "jquery";

function setThreadIndicator(form) {
  const $form = $(form);
  const $parentEntry = $form.find("#id_parent_entry");
  const $indicator = $form.find("[data-thread-indicator]");
  if ($parentEntry.val() == "") {
    $indicator.hide();
  } else {
    $indicator.children("div").text("Continuing #" + $parentEntry.val());
    $indicator.show();
  }
}

$(document).on("change", "#id_parent_entry", function (e) {
  setThreadIndicator(e.target.parentNode);
});

$(document).on("click", "[data-continue-thread]", function (e) {
  e.preventDefault();
  const $this = $(this);
  const $form = $("#permanent-entry-form");
  $form.find("#id_parent_entry").val($this.data("continue-thread"));
  setThreadIndicator($form);
});

$(document).on("click", "[data-thread-indicator] button", function (e) {
    e.preventDefault();
    const $form = $("#permanent-entry-form");
    $form.find("#id_parent_entry").val("");
    setThreadIndicator($form);
});
